import moment from 'moment'
export const conventDateTime = (datetime) =>{
    let ret = "--"
    if(datetime !== undefined){
      let m = moment(datetime.toDate());     
      ret =m.format("DD/MM/YYYY HH:mm")
    }
    return ret
  }

  export const conventDate = (datetime) =>{
    let ret = "--"
    if(datetime !== undefined){
      let m = moment(datetime.toDate());     
      ret =m.format("DD/MM/YYYY")
    }
    return ret
  }

  export const conventTime = (datetime) =>{
    let ret = "--"
    if(datetime !== undefined){
      let m = moment(datetime.toDate());     
      ret =m.format("HH:mm")
    }
    return ret
  }