
export const  bundle = {
  "zh-hk":{
    i_agree_with_the_term: "本人已閱讀及同意遵守上述條款及細則",
    agree_btn:"本人同意"
    
  } ,
  "en-hk":{
    i_agree_with_the_term: " I agree with the Terms and Conditions",
    agree_btn:"I AGREE"
  }
}


