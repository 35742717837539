import axios from 'axios'


export default class Api{
  constructor(){
    this.api_url = process.env.REACT_APP_api_endpoint
    this.api_token= null
  }
  init = ()=>{
    this.api_token = null;
    let headers = {
      Accept: "application/json"
    };
    if (this.api_token) {
      headers.Authorization = `Bearer ${
        this.api_token
      }`;
    }
    this.client = axios.create({baseURL: this.api_url, timeout: 31000, headers: headers});
    return this.client;

  }

  checkDriverActivation = (code) =>{
    return this.init().get("/activation/"+code );
  }

  confirmActivation = (code) =>{
    return this.init().post("activation/"+code)
  }



}