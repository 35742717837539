import React, { useState, useEffect } from "react"
import { useParams } from 'react-router-dom';
import { db } from '../utils/firebase.js'

import { getDoc, doc } from "firebase/firestore";
import { conventDate, conventTime } from "../utils/util.js";
import "./receipt.scss";

import {Table,TableBody,Backdrop,CircularProgress,TableCell,TableContainer,TableHead,TableRow} from '@mui/material';
import GoogleMapReact from 'google-map-react';
import { sum } from "lodash";

const PointComponent = ({ point }) => (
    
  <div className={point === "S" ? "pointstart" : "pointend"} >
    {point}
  </div>
);

const Receipt = () => {
  const params = useParams();
  const [trip, setTrip] = useState({})
  const [loading,setLoading] = useState(true)
  const defaultProps = {
    center: {
      //lat: parseInt(process.env.REACT_APP_receiptDefaultLatitude,10), 
      lat: parseFloat(process.env.REACT_APP_receiptDefaultLatitude),
      lng: parseFloat(process.env.REACT_APP_receiptDefaultLongitude)
    },
    zoom: parseInt(process.env.REACT_APP_receiptDefaultZoom, 10),
    api_key: process.env.REACT_APP_apiKey
  };

  useEffect(() => {
    const tripRef = doc(db, "trips", params.id)
    getDoc(tripRef).then(d => {
      const data = d.data()
      let payment =null
      try{
        payment = JSON.parse(data.payment_information.authorize)
        }catch{}

      setTrip({ ...data, id: d.id , payment_information : payment  })
      setLoading(false)
    })
    document.title = "Receipt"
  }, [])


  useEffect(()=>{
    console.log("trip",trip)
    
  },[trip])

  return (
    <main>
      <div className="container receipt-container">
        <div className="receipt-header">
          <div className="logo">
          </div>
          <div className="text">Thank you for using DASH </div>
        </div>
        <div className="receipt-body">
          <div className="info">
            <div className="price">
              <div className="title">Total Paid</div>
              <div className="price-amount">HK${sum([trip.total , trip.adjustment])   || 0}</div>
            </div>
            <div className="blocks">

              <div className="column">
                <div className="info-block">
                  <dt>Date</dt>
                  <dd>{trip.trip_start ? conventDate(trip.trip_start) : ''}</dd>
                </div>
                <div className="info-block">
                  <dt>Txn#</dt>
                  <dd>{trip.id }</dd>
                </div>

              </div>
              <div className="column">
                <div className="info-block">
                  <dt>Start</dt>
                  <dd>{trip.trip_start ? conventTime(trip.trip_start) : ''}</dd>
                </div>

                <div className="info-block">
                  <dt>End</dt>
                  <dd>{trip.trip_start ? conventTime(trip.trip_end) : ''}</dd>
                </div>


                <div className="info-block car-plate">
                  <dd><span>{trip.license_plate || ''}</span></dd>
                </div>

              </div>
            </div>
          </div>

          <div className="map">
            <div className="column">
              <div className="title">Route Detail</div>

              <dl className="route-info">
                
                <div className="left">
                <dd className="start">Start</dd>
                <dt>{trip.location_start_address}</dt>
                </div>
                <div className="right">
                <dd className="end">End</dd>
                <dt>{trip.location_end_address}</dt>
                </div>
              </dl>
            </div>
            <div className="column map-container">
            {trip.location_start && trip.location_end ?
              <GoogleMapReact
                defaultCenter={{
                  lat: trip.location_start._lat,
                  lng: trip.location_start._long
                }}
                defaultZoom={defaultProps.zoom}
                bootstrapURLKeys={{ key: process.env.REACT_APP_apiKey }}>

                <PointComponent
                  lat={trip.location_start._lat}
                  lng={trip.location_start._long}
                  point={"S"}
                />

                <PointComponent
                  lat={trip.location_end._lat}
                  lng={trip.location_end._long}
                  point={"E"}
                />
              </GoogleMapReact> :
              "Map loading"
            }
            </div>
          </div>
          <div className="route">
            <div className="table-container">

              <TableContainer>

                <Table>
                  <TableHead>
                    <TableRow>
                     {/* <TableCell></TableCell>
                       <TableCell>Location</TableCell> */}
                      <TableCell>Time</TableCell>
                      <TableCell>Fare</TableCell>
                      <TableCell>Extra</TableCell>
                      <TableCell>Distance</TableCell>
                      <TableCell>Wait Time</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>

                    <TableRow className="odd-color">
                      <TableCell colSpan={6}>
                     
                      <dl className="route-info">
                        <dd className="start">Start</dd>
                        <dt>{trip.location_start_address}</dt>
                      </dl>

                      </TableCell>
                    </TableRow>
                    <TableRow className="odd-color">
                      <TableCell>{trip.trip_start ? conventTime(trip.trip_start) : '--'}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                    </TableRow>
                    {/* <TableRow className="even-color">
                      <TableCell colSpan={6}>
                     
                      <dl className="route-info">
                        <dd className="stop">Stop</dd>
                        <dt>Hopewell Centre</dt>
                      </dl>

                      </TableCell>
                    </TableRow>
                    
                    <TableRow className="even-color">
                      <TableCell>20:00</TableCell>
                      <TableCell>$32.00</TableCell>
                      <TableCell>$0.00</TableCell>
                      <TableCell>1.9</TableCell>
                      <TableCell>03:55</TableCell>
                    </TableRow> */}

                    <TableRow className="odd-color">
                      <TableCell colSpan={6}>
                      <dl className="route-info">
                        <dd className="end">End</dd>
                        <dt>{trip.location_end_address}</dt>
                      </dl>
                      </TableCell>
                    </TableRow>
                    <TableRow className="odd-color">
                      <TableCell>{trip.trip_end ? conventTime(trip.trip_end) : '--'}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

              </TableContainer>
            </div>
          </div>
          <div className="payment">
            <div className="title">Payment Details</div>
            <div className="blocks flex-column">
            <div className="column"><div className="info-block credit-card">
                  <dt>Credit Card 
                      { (trip.payment_information?.payment?.paymentMethod === 'VISA'||
                        trip.payment_information?.payment?.paymentMethod === 'MASTER') &&
                        <img className="payment-logo" src={"/icons8-"+trip.payment_information.payment.paymentMethod.toLowerCase() +".svg"} alt="Dash" height={48} />
                      }
                  </dt>
                  <dd>{trip.payment_information?.payment?.payer || '-' }</dd>
                </div>
                <div className="info-block total">
                  <dt>Total</dt>
                  <dd>HK${ sum([trip.total , trip.adjustment]) }</dd>
                </div></div>

            <div className="column"><div className="info-block">
                  <dt>Fare</dt>
                  <dd>${trip.fare || 0}</dd>
                </div>
                <div className="info-block">
                  <dt>Extra</dt>
                  <dd>${trip.extra || 0}</dd>
                </div>
                <div className="info-block">
                  <dt>Tips</dt>
                  <dd>${trip.dash_tips || 0}</dd>
                </div>
                <div className="info-block">
                  <dt>Fee</dt>
                  <dd>${trip.dash_fee || 0}</dd>
                </div>
                <div className="info-block">
                  <dt>Adjustment</dt>
                  <dd>${trip.adjustment || 0}</dd>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="terms">
              <div className="button">
                Terms & Conditions
              </div>
            </div>
            <div className="center">Contact Us@ <br></br>+852 6486 3366<br></br>By Phone or by Whatsapp<br></br><br></br><span className="rights">Vis Mobility Ltd. All right reserved.</span> </div>
          </div>
        </div>
      </div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
         <CircularProgress color="inherit"/>
       </Backdrop> 
    </main>
  )
}

export default Receipt


